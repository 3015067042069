import { useDisplayMode } from '#app/hooks/use-display-mode';
import {
    localeToTwaBadgeUrl,
    localeToTwaStoreUrl,
} from '#app/utils/app-stores';

export function StoreButtons({ locale }: { locale: string }) {
    const twaBadgeUrl = localeToTwaBadgeUrl(locale);
    const twaStoreUrl = localeToTwaStoreUrl(locale);
    const displayMode = useDisplayMode();

    return displayMode._tag === 'browser' ? (
        <div className="flex items-center justify-center space-x-3 sm:flex-row sm:justify-around sm:space-x-5 sm:space-y-0">
            <div>
                <a href={twaStoreUrl}>
                    <img
                        className="aspect-[270/80] rounded-xl"
                        alt="Google Play Store"
                        src={twaBadgeUrl}
                        width={270}
                        height={80}
                    />
                </a>
            </div>
        </div>
    ) : null;
}
