import { ArrowRightIcon, LockClosedIcon } from '@heroicons/react/20/solid'
import {
	json,
	type MetaFunction,
	redirect,
	type ActionFunction,
	type LoaderFunctionArgs,
} from '@remix-run/node'
import { Form, Link, useActionData, useLoaderData } from '@remix-run/react'
import { getMDXComponent } from 'mdx-bundler/client'
import { useEffect, useMemo } from 'react'
import isEmail from 'validator/lib/isEmail'
import { type z, type ZodIssue } from 'zod'
import { toastError } from '#app/components/custom-toaster'
import { InputLabelError } from '#app/components/form-elements'
import {
	MdxImage,
	MdxLink,
	MdxShareButton,
	MdxTable,
} from '#app/components/mdx-elements'
import { ScrollBackToTopFab } from '#app/components/scroll-back-to-top-fab'
import { StoreButtons } from '#app/components/store-buttons'
import { DEFAULT_LOCALE } from '#app/data/config'
import { SESSION_TYPE_USER } from '#app/data/config.server'
import {
	INDEX_HREFLANG_LOCALES,
	SITE_URL,
	YANDEX_SITE_VERIFICATION,
} from '#app/env.remix/config'
import { getIndexSchemas, indexMeta } from '#app/env.remix/index-meta'
import {
	updateProfileIsOnline,
	userLoginToProfile,
} from '#app/models/profile.server'
import { prisma } from '#app/utils/db.server'
import {
	languageIdToLocale,
	loadTranslations,
	translate,
} from '#app/utils/i18n.server'
import { getIndexMdx } from '#app/utils/mdx.server'
import { getParamsLocale, requestToIp } from '#app/utils/request.server'
import { generateHreflang, getSeoMeta } from '#app/utils/seo'
import { createSessionData, requireGuest } from '#app/utils/session.server'
import { indexLoginSchema } from '#app/utils/zod.server'

export let meta: MetaFunction = ({ params }) => {
	let locale = params.locale ?? DEFAULT_LOCALE
	return [
		...getSeoMeta(indexMeta[locale] ?? indexMeta.en),
		{ name: 'yandex-verification', content: YANDEX_SITE_VERIFICATION },
		{
			'script:ld+json': getIndexSchemas(locale),
		},
		{ tagName: 'link', rel: 'canonical', href: `${SITE_URL}/en` },
		...generateHreflang({ locales: INDEX_HREFLANG_LOCALES, url: '' }),
	]
}

let i18nKeys = [
	'contact_us_username',
	'cta_join',
	'cta_login',
	'cta_share',
	'email',
	'password',
] as const
type I18nKeys = (typeof i18nKeys)[number]

export async function loader({ params, request }: LoaderFunctionArgs) {
	let locale = getParamsLocale(params)

	await requireGuest({ locale, request })
	let i18n = loadTranslations<I18nKeys>(locale, i18nKeys)
	let { code } = await getIndexMdx(locale)

	return json({
		i18n,
		locale,
		code,
		sharedUrl: `${SITE_URL}/${locale}`,
	})
}

type ActionData = z.inferFlattenedErrors<typeof indexLoginSchema>

export let action: ActionFunction = async ({ params, request }) => {
	let locale = getParamsLocale(params)

	await requireGuest({ locale, request })

	let formData = await request.formData()
	let values = Object.fromEntries(formData)
	let ipString = requestToIp(request)
	let parsedValues = await indexLoginSchema.safeParseAsync(values)

	if (!parsedValues.success) {
		console.error(
			{ ip: ipString, values, ...parsedValues.error.flatten() },
			'index:error',
		)

		return json(
			parsedValues.error.flatten((i: ZodIssue) =>
				translate({ key: i.message, locale }),
			),
			{ status: 400 },
		)
	}

	let profile = await userLoginToProfile(parsedValues.data)

	if (profile == null) {
		// if email not exists - pre-fill join form
		if (isEmail(parsedValues.data.emailOrUsername)) {
			let profileWithEmail = await prisma.profile.findFirst({
				where: { email: parsedValues.data.emailOrUsername },
			})

			if (profileWithEmail == null) {
				let searchParams = new URLSearchParams()
				searchParams.set('email', parsedValues.data.emailOrUsername)
				searchParams.set('password', parsedValues.data.password)
				throw redirect(`/${locale}/join?${searchParams.toString()}`)
			}
		}
		return json<ActionData>(
			{
				formErrors: [translate({ key: 'email_or_password_error', locale })],
				fieldErrors: {},
			},
			400,
		)
	}

	if (!profile.isOnline) {
		await updateProfileIsOnline({
			activityIp: requestToIp(request),
			isOnline: true,
			profileId: profile.id,
		})
	}

	return await createSessionData({
		request,
		sessionType: SESSION_TYPE_USER,
		typeId: profile.id,
		redirectTo: `/${languageIdToLocale(profile.languageId)}/user/home`,
	})
}

export default function Index() {
	let data = useLoaderData<typeof loader>()
	let actionData = useActionData<ActionData>()

	let components = useMemo(() => {
		return {
			a: MdxLink,
			table: MdxTable,
			Image: MdxImage,
			ShareButton: () => (
				<MdxShareButton
					label={data.i18n.cta_share}
					sharedUrl={data.sharedUrl}
				/>
			),
		}
	}, [data.i18n.cta_share, data.sharedUrl])

	let MDXContent = useMemo(() => getMDXComponent(data.code), [data.code])

	useEffect(() => {
		if (actionData?.formErrors[0]) {
			toastError(actionData.formErrors[0])
		}
	}, [actionData])

	return (
		<div className="flex-grow">
			<div className="mx-auto max-w-2xl flex-grow px-4">
				<Form
					className="grid-col-1 grid gap-4 pt-5 sm:grid-cols-2"
					method="post"
					noValidate
				>
					<InputLabelError
						type="email"
						id="emailOrUsername"
						name="emailOrUsername"
						error={actionData?.fieldErrors.emailOrUsername}
						label={`${data.i18n.contact_us_username} / ${data.i18n.email}`}
					/>
					<InputLabelError
						type="password"
						id="password"
						name="password"
						error={actionData?.fieldErrors.password}
						label={data.i18n.password}
					/>
					<button
						className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-4 text-xl font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-70 sm:col-span-2"
						type="submit"
					>
						<LockClosedIcon className="-ml-3 mr-3 h-6 w-6" aria-hidden="true" />
						<span>{data.i18n.cta_login}</span>
					</button>
				</Form>
				<JoinButton label={data.i18n.cta_join} locale={data.locale} />
				<div className="my-5 lg:my-10">
					<StoreButtons locale={data.locale} />
				</div>
			</div>
			<div className="mx-auto mb-8 bg-slate-50 px-4 dark:bg-slate-900 sm:mt-10 sm:px-6 md:px-8 xl:max-w-6xl xl:px-12">
				<div className="prose prose-slate mx-auto px-4 py-4 dark:prose-invert md:prose-lg lg:prose-xl prose-h1:tracking-tight prose-a:text-blue-700 hover:prose-a:text-blue-400 dark:prose-a:text-teal-500 dark:hover:prose-a:text-teal-400 md:px-10">
					{/* @ts-ignore */}
					<MDXContent components={components} />
				</div>
			</div>
			<JoinButton label={data.i18n.cta_join} locale={data.locale} />
			<ScrollBackToTopFab />
		</div>
	)
}

function JoinButton({ label, locale }: { label: string; locale: string }) {
	return (
		<div className="flex justify-center">
			<Link
				className="mx-4 my-8 flex items-center space-x-5 rounded-full bg-gradient-to-tr from-indigo-600 via-purple-900 to-rose-700 px-10 py-4 text-xl font-semibold text-indigo-50 drop-shadow-lg transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 sm:text-2xl md:px-20"
				to={`/${locale}/join`}
			>
				<span>{label}</span>
				<span>
					<ArrowRightIcon className="h-7 w-7" />
				</span>
			</Link>
		</div>
	)
}
