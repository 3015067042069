import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

// https://tailwind-elements.com/docs/standard/components/scroll-back-to-top-button/
export function ScrollBackToTopFab() {
  let [display, setDisplay] = useState(false);

  useEffect(() => {
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      setDisplay(
        document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
      );
    }
  }, []);

  let onClick = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return display ? (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        className="inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-3 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        id="btn-back-to-top"
        onClick={onClick}
      >
        <ArrowUpIcon className="h-8 w-8" aria-hidden="true" />
      </button>
    </div>
  ) : null;
}
