import { getBlogPostingSchema, getFaqPageSchema } from '#app/utils/schema'
import { type GetSeoMetaRequest } from '#app/utils/seo'
import { SITE_NAME, SITE_URL } from './config'

export const indexMeta: Record<string, GetSeoMetaRequest> = {
	en: {
		title: 'Dating in Thailand - Best Thai Dating Site to Meet Thai Women',
		description:
			'Meet Thai women from all over Thailand at the best Thai dating site - ThaiRomances.com. Join free today for a fun & safe Thai dating experience.',
		keywords:
			'dating Thai woman, Thai women, dating in Thailand, Thai girl dating',
		image: `${SITE_URL}/static/e/index/beauty-of-thailand-women-16x9.jpg`,
		url: `${SITE_URL}/en`,
		updatedAt: '2022-05-03',
	},
	th: {
		title: 'หาแฟน | หาคู่ | หาเพื่อนคุยไลน์ | หาคู่รัก',
		description: 'หาคู่ต่างชาติ | ฝรั่ง โสด | แช ท หา แฟน | หาแฟนต่างชาติ',
		image: `${SITE_URL}/static/e/index/dating-in-thailand-16x9.jpg`,
		url: `${SITE_URL}/th`,
		ogLocale: 'th_TH',
		updatedAt: '2022-09-08',
	},
}

export function getIndexSchemas(locale: string) {
	const meta = locale === 'th' ? indexMeta.th : indexMeta.en

	const blogPosting = getBlogPostingSchema({
		authorName: SITE_NAME,
		dateModified: meta.updatedAt,
		datePublished: meta.updatedAt,
		description: meta.description,
		image:
			locale === 'th'
				? [
						{
							url: `${SITE_URL}/static/e/index/dating-in-thailand-16x9.jpg`,
							width: '1200',
							height: '675',
						},
						{
							url: `${SITE_URL}/static/e/index/dating-in-thailand-4x3.jpg`,
							width: '1200',
							height: '900',
						},
						{
							url: `${SITE_URL}/static/e/index/dating-in-thailand-1x1.jpg`,
							width: '600',
							height: '600',
						},
					]
				: [
						{
							url: `${SITE_URL}/static/e/index/beauty-of-thailand-women-16x9.jpg`,
							width: '1200',
							height: '675',
						},
						{
							url: `${SITE_URL}/static/e/index/beauty-of-thailand-women-4x3.jpg`,
							width: '1200',
							height: '900',
						},
						{
							url: `${SITE_URL}/static/e/index/beauty-of-thailand-women-1x1.jpg`,
							width: '600',
							height: '600',
						},
					],
		title: meta.title,
		url: meta.url,
	})

	return locale === 'th'
		? blogPosting
		: [
				blogPosting,
				getFaqPageSchema([
					{
						question:
							'Is an online Thai-focussed dating site a better way to meet Thailand women?',
						answer:
							'Meeting Thai women on an online Thai dating website has been favored since Thailand has had Internet services. Discerning single men, opt for ThaiRomances as a cost-saving measure compared to the traditional pub-crawl & bargirl method. I.e., over six months, you can spend only $45.86 for a full membership upgrade compared to $24,000 for Thai bargirls. It is better to use those savings for living expenses and holidays.',
					},
					{
						question:
							'What are the essential basic rules when using a Thailand dating site?',
						answer:
							'Firstly, understand that most Thai women have low English level skills; you must only use short & concise sentences. Use ThaiRomances pre-canned greeting texts will help you get your first conversations underway. All Thai dating sites allow anyone to join. It is your responsibility to conduct due diligence on all Thai girls; vet who they say they are and what it is they want.',
					},
				]),
			]
}
